import React, { useEffect, useState } from "react";
import { StyledBox, Wrapper } from "./style";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/base";
import { API } from "../../services/api";
import { ButtonSpinner, MyButton } from "../../components";

const SettingsPage = () => {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const userData = useSelector((store) => store.userData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [eImzoDate, setEImzoDate] = useState();


  // updateEImzoDate: (payload) => axiosInstance.post(`/e-imzo/eimzo-time/${payload}`),
  // //get
  // getEmzoDate: () => axiosInstance.get(`/e-imzo/eimzo-time`),


  const getEImzoDate = async() => {
    const response = await API.getEmzoDate();
    setEImzoDate(response.data);
  }

  useEffect(() => {
    getEImzoDate();
  },[])

  const updateEImzoDate = async(eimzoDate) => {
    await API.updateEImzoDate(eimzoDate)
    .then((res) => {
      console.log("res data =>" + res.data);
    })
  }
  

  return (
    <Wrapper>
      <div className="container">
        <StyledBox>
          <Box className="profile-form">
            <label>
              {t("protocolSigningTime")}
              <input 
                type="number" 
                className="profile-input" 
                defaultValue={eImzoDate}
                onChange={(e) => setEImzoDate(e.target.value)}
                />
            </label>
          </Box>
          <MyButton
              color="#208667"
              background="#FFFFFF"
              border="#208667"
              width="100%"
              margin="30px 0 0 0"
              onClick={() => {updateEImzoDate(eImzoDate)}}
              type="submit"
              form="agendaForm"
            >
              {t("protocolSigningTime")}
            </MyButton>
          {/* <Button onClick={() => {updateEImzoDate(eImzoDate)}}>
          {t("protocolSigningTime")}
          </Button> */}
        </StyledBox>
      </div>
    </Wrapper>
  );
};

export default SettingsPage;
