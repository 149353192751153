import { Box } from "@mui/material";
import { Wrapper } from "./style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MeetingGeneral from "./General";
import Participant from "./Participant";
import Agenda from "./Agenda/Agenda";
import { useTranslation } from "react-i18next";

function MeetingCreate() {
  // const meeting = JSON.parse(localStorage.getItem("newMeetnigId"));
  const [meeting, setMeeting] = useState(
    JSON.parse(localStorage.getItem("newMeetnigId"))
  );
  const { hash } = useLocation();
  const [active, setActive] = useState(
    !meeting?.id ? "general" : hash.slice(1)
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menuData = [
    { id: 1, label: t("meetingData"), hash: "general" },
    { id: 2, label: t("meetingParticipants"), hash: "participant" },
    { id: 3, label: t("dayIssues"), hash: "agenda" },
  ];

  useEffect(() => {
    if (!meeting) {
      navigate(`/admin/meetings/create#general`);
    }
    return () => {
      setActive("general");
      localStorage.removeItem("newMeetnigId");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeMenu = (value) => {
    if (meeting) {
      setActive(value);
      navigate(`/admin/meetings/create#${value}`);
    }
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="user-top">
          <Link className="user-link" to="/admin">
            {t("home")}
          </Link>
          <Link className="user-link" to="/admin/meetings">
            {t("meetings")}
          </Link>
          <h5>{t("meetingCreate")}</h5>
        </Box>
        <Box className="company-list">
          <Box className="company-left">
            {menuData.map((el) => (
              <button
                onClick={() => changeMenu(el.hash)}
                className={`company-link ${el.hash === active && "active"}`}
              >
                {el.label}
              </button>
            ))}
          </Box>

          <Box className="meeting-right">
            {active === "general" ? (
              <MeetingGeneral setActive={setActive} setMeeting={setMeeting} />
            ) : active === "participant" ? (
              <Participant setActive={setActive} meetingId={meeting?.id} />
            ) : active === "agenda" ? (
              <Agenda
                setActive={setActive}
                meetingId={meeting?.id}
                meetingType={meeting?.meetingType}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </div>
    </Wrapper>
  );
}

export default MeetingCreate;
