import styled from "styled-components";
import { MIN_LENGTH } from "../../../../../constants";

export const Wrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  background-image: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .meeting-created-box {
    padding: 30px !important;
    margin-bottom: 60px !important;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
  }

  .ted-title {
    margin-bottom: 30px;
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .meeting-created-box-footer {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
  }
`;
