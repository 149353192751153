import { dispatch } from "../redux";
import { BASE_URL } from "../constants";
import Axios from "axios";

export const axiosInstance = Axios.create({
  baseURL: BASE_URL,
});

// run before each request
axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = token;
        config.headers["Accept-Language"] = "uz";
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// run after each response
// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     const status = error.response?.status;
//     console.log('Run after api call', status);
//     // if unauthenticated error, reset persisted data and log out the user
//     if (status === 401) {
//       dispatch.auth.logoutAsync();
//       return error;
//     }
//     return Promise.reject(error);
//   },
// );

export const API = {
  //post
  login: (payload) => axiosInstance.post("/authenticate", payload),
  addMemberManagers: (payload) =>
    axiosInstance.post("/member/managers", payload),
  addReestrByMeetingId: (payload) =>
    axiosInstance.post("/reestr/parse", payload),
  createCompanyData: (payload) => axiosInstance.post("/company", payload),
  createMemberManagment: (payload) =>
    axiosInstance.post("/moder/user", payload),
  userImageFileSave: (payload) => axiosInstance.post("/file/save", payload),
  createNewMeeting: (payload) => axiosInstance.post("/meeting", payload),
  addMeetingFile: (payload) => axiosInstance.post("/file/upload", payload),
  createMeetingAgenda: (payload) => axiosInstance.post("/agenda", payload),
  createAgendaFile: (payload) => axiosInstance.post("/agenda-file", payload),
  createUserData: (payload) => axiosInstance.post("/moder/user", payload),
  createBallot: (payload) => axiosInstance.post("/ballot", payload),
  faceIdCheck: (payload) =>
    axiosInstance.post(`/face-id/check?userId=${payload?.id}`, payload?.file),
  createCompanyImage: (payload) =>
    axiosInstance.post("/file/upload/logo", payload),
  filterMeetingData: (payload) =>
    axiosInstance.post("/meeting/spec-filter", payload),
  notificationRedPost: (payload) =>
    axiosInstance.post(`/notification/read`, payload),
  updateProtocol: (payload) => axiosInstance.post("/extraAQ", payload),
  uploadMeetingVideo: (payload) => axiosInstance.post(`/meeting/upload-meeting-video`, payload),
  updateEImzoDate: (payload) => axiosInstance.post(`/e-imzo/eimzo-time?time=${payload}`),
  //get
  getEmzoDate: () => axiosInstance.get(`/e-imzo/eimzo-time`),
  getAllFileByAgendaId: (payload) => axiosInstance.get(`/agenda-file/by-agenda/${payload}`),
  getBallotsResultByMeetingId: (payload) => axiosInstance.get(`/ballot/get-ballot-result/${payload}`),
  getAllBallotByAgendaId: (payload) => axiosInstance.get(`/ballot/by-agenda?agendaId=${payload}`),
  getCompanyDetailsBySecretaryId: (payload) => axiosInstance.get(`/company-details/by-secretary/${payload}`),
  getAllMeetingVideoByMeetingId: (payload) => axiosInstance.get(`/meeting/getAllMeetingVideo/${payload.meetingId}`),
  downloadMeetingVideo: (payload) => axiosInstance.get(`/meeting/download-meeting-video/${payload.meetingVideoId}`),
  getMeetingQrCode: (payload) => axiosInstance.get(`/meeting/data/${payload}`),
  getUserData: (payload) => axiosInstance.get("/account", payload),
  getCompaniesData: (payload) =>
    axiosInstance.get(`/company?size=100&orderType=${payload.orderType}`, payload),
  getCompanyByID: (payload) =>
    axiosInstance.get(`/company/${payload}`, payload),
  getModerUserData: (payload) =>
    axiosInstance.get("/moder/user?size=4000", payload),
  getPaginationModerUserData: (payload) =>
    axiosInstance.get(
      `/moder/user?page=${payload.page}&size=${payload.size}&orderType=${payload.orderType}`,
      payload
    ),
  getModerUserById: (payload) =>
    axiosInstance.get(`/moder/user/${payload}`, payload),
  getModerUserSearch: (payload) =>
    axiosInstance.get(
      `/moder/user/filter?field=FULL_NAME&size=10&value=${payload}`,
      payload
    ),
  getAllMeetings: (payload) => axiosInstance.get("/meeting?size=1000", payload),
  getAllMeetingsPaginate: (payload) =>
    axiosInstance.get(
      `/meeting?page=${payload.page}&size=${payload.size}`,
      payload
    ),
  getMeetingByID: (payload) => axiosInstance.get(`/meeting/${payload}`),
  getCities: () => axiosInstance.get("/city"),
  getMembers: () => axiosInstance.get("/member"),
  getReestrByMeetingUrl: () => axiosInstance.get("/reestr/download"),
  getMembersByMeeting: (payload) =>
    axiosInstance.get(
      `/member/by-meeting?meetingId=${payload?.meetingId}&fromReestr=${payload?.fromReestr}`
    ),
  getAllCompanies: () => axiosInstance.get("/company"),
  checkEimzo: () => axiosInstance.get("/e-imzo"),
  getAgendaByMeetingID: (payload) =>
    axiosInstance.get(`/agenda/by-meeting?meetingId=${payload}`),
  getMeetingFile: (payload) => axiosInstance.get(`/file/meeting/${payload}`),
  getCompanyImage: (payload) => axiosInstance.get("file/logo/", payload),

  getChatHistory: (payload) => axiosInstance.get(`/topic?agendaId=${payload}`),

  grtUnreadNotification: (payload) =>
    axiosInstance.get(`/notification/unread`, payload),
  getAgendaAnswers: (payload) =>
    axiosInstance.get(`/answerquestion/meetingId/${payload.meetingId}/${payload.agendaId}`),
  // getAgendaAnswersByAgendaId: (payload) =>
  //   axiosInstance.get(`/answerquestion/agendaId/${payload}`),
  getUserByMeeting: (payload) =>
    axiosInstance.get(`/member/users-by-meeting/${payload}`),

  //put
  updateCompanyDetails: (payload) => axiosInstance.put("/company-details/update", payload),
  updateUserData: (payload) => axiosInstance.put("/moder/user", payload),
  updateMeetingData: (payload) => axiosInstance.put("/meeting", payload),
  updateCompanyData: (payload) => axiosInstance.put("/company", payload),
  updateAgendaData: (payload) => axiosInstance.put("/agenda", payload),
  updateMember: (payload) => axiosInstance.put("/member", payload),

  //delete
  adminCompaniesDelete: (payload) =>
    axiosInstance.delete(`/company/${payload}`, payload),
  adminUsersDelete: (payload) =>
    axiosInstance.delete(`/moder/user/${payload}`, payload),
  userProfileImageDelete: (payload) =>
    axiosInstance.put(`/moder/user/${payload}`),
  deleteMeetingById: (payload) => axiosInstance.delete(`/meeting/${payload}`),
  deleteMember: (payload) => axiosInstance.delete(`/member/${payload}`),
  deleteFile: (payload) => axiosInstance.delete(`/file/${payload}`),
  deleteVoit: (payload) => axiosInstance.delete(`/ballot/${payload.id}`),
  deleteAgenda: (payload) => axiosInstance.delete(`/agenda/${payload}`),
  deteleMeetingAgenda: (payload) =>
    axiosInstance.delete(`/agenda/${payload}`, payload),
  deleteAgendaFile: (payload) =>
    axiosInstance.delete("/agenda-file/" + payload),
  deleteMeetingVideo: (payload) => axiosInstance.delete(`/meeting/delete-meeting-video/` + payload),

  // USER SIDE APIES //
  //  GET ALL COMPANIES
  getCompanies: (payload) => axiosInstance.get(`/company/user/${payload.id}`),
  //  GET ALL COMPANY DATA
  getCompanyData: (payload) => axiosInstance.get(`/company/${payload.id}`),
  // GET COMPANY'S MEETINGS
  getCompanyMeetings: (payload) =>
    axiosInstance.get(
      `/meeting/by-user?userId=${payload.userId}&companyId=${payload.compId}`
    ),
  // GET MEETING
  getMeeting: (payload) => axiosInstance.get(`/meeting/${payload.meetingId}`),
  getSearchMeeting: (payload) =>
    axiosInstance.post("/meeting/spec-filter", payload),
  // GET MEMBER ROLE  BY MEETING
  getMemberRoleByMeeting: (payload) =>
    axiosInstance.get(
      `/member/member-types?userId=${payload.userId}&meetingId=${payload.meetingId}`
    ),
  // GET AGENDA BY MEETING
  getAgendaByMeeting: (payload) =>
    axiosInstance.get(`/agenda/by-meeting?meetingId=${payload.id}`),
  // GET FILES BY MEETING ID
  getMeetingFiles: (payload) =>
    axiosInstance.get(`/agenda-file/get-by-meeting/${payload}`),
  // GET AGENDA BY ID
  getAgendaById: (payload) => axiosInstance.get(`/agenda/${payload}`),
  // GET FILES BY MEETING
  getFilesByMeeting: (payload) =>
    axiosInstance.get(`/file/meeting/${payload.id}`),
  // GET LOGGINGS BY MEETING
  getLoggingByMeeting: (payload) =>
    axiosInstance.get(`/logging/by-meeting?meetingId=${payload.id}`),
  // UPDATE AGENDA
  updateAgenda: (payload) => axiosInstance.patch("/agenda", payload),
  // GET QUESTIONS BY MEETING ID
  getQuestionByMeeting: (payload) =>
    axiosInstance.get(`/question/by-meeting?meetingId=${payload.id}`),
  // GET QUESTIONS BY MEMBER ID
  getQuestionByMember: (payload) =>
    axiosInstance.get(`/question/by-member/${payload.memberId}`),
  // GET QUESTIONS BY MEMBER ID
  getVoitById: (payload) =>
    axiosInstance.get(`ballot/by-voting?votingId=${payload.id}`),
  getBallotVoitsById: (payload) =>
    axiosInstance.get(`/ballot/by-voting-details?votingId=${payload.id}`),
  getBallotAgendaMemberById: (payload) =>
    axiosInstance.get(
      `/ballot/ballots-by-agen-mem?agendaId=${payload.agendaId}&memberId=${payload.memberId}`
    ),
  // CREATE MEETING QUESTION
  createQuestion: (payload) => axiosInstance.post(`/question`, payload),
  //  update
  //  UPDATE MEETING STATUS
  updateMeetingStatus: (payload) =>
    axiosInstance.patch(
      `/meeting/meeting-status?meetingId=${payload.id}&statusEnum=${payload.status}`
    ),
  // CHECK MEETING PROTOCOL
  checkProtocol: (payload) =>
    axiosInstance.post(`/answerquestion/check/meeting?meetingId=${payload}`),
  // CREATE MEETING PROTOCOL
  createProtocol: (payload) => axiosInstance.put(`/answerquestion`, payload),
  editProtocol: (payload) => axiosInstance.put("/answerquestion/update", payload),
  getProtocol: (payload) => axiosInstance.get("/answerquestion", payload),
  //  UPDATE MEMBER
  updateMemberData: (payload) => axiosInstance.put(`/member/${payload.id}`),
  //delete
  // get protocol pdf
  getProtocolPdf: (payload) =>
    axiosInstance.get(`/meeting/generate-pdf?meetingId=${payload}`),
  // get meeting member
  getMeetingMember: (payload) =>
    axiosInstance.get(
      `/member/by-meeting?meetingId=${payload.meetingId}&fromReestr=${payload.fromReestr}`
    ),

  getSignInProtocol: (data) =>
    axiosInstance.get(`/usermeeting/signed/${data.id}`),
  getProtocolQRHtml: (data) =>
    axiosInstance.get(`/meeting/qr-html?meetingId=${data.meetingId}&lang=${data.lang}`),
  getProtocolHtml: (data) =>
    axiosInstance.get(`/meeting/pdf-html?meetingId=${data.meetingId}&lang=${data.lang}`),
  resetPdfHtml: (data) =>
    axiosInstance.get(`/meeting/reset-pdf-html?meetingId=${data.meetingId}&lang=${data.lang}`),
  resetQRHtml: (data) =>
    axiosInstance.get(`/meeting/reset-qr-html?meetingId=${data.meetingId}&lang=${data.lang}`),
  saveProtocolHtml: (payload) =>
      axiosInstance.post(`/meeting/update-pdf-html`,payload),
  saveProtocolQRHtml: (payload) =>
      axiosInstance.post(`/meeting/update-qr-html`,payload),
};
