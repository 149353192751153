import React from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { AgendaTable } from "./agendaListStyle";
import { AgendaListItem } from "../";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const AgendaList = memo(
  ({ agendaData, speakers, getAgendaRefetch, agendaDeleteMutate }) => {
    const { t } = useTranslation();

    const meetingType = JSON.parse(
      localStorage.getItem("newMeetnigId")
    ).meetingType;

    return (
      <AgendaTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>N</TableCell>
            <TableCell>{t("agenda")}</TableCell>
            {meetingType !== "ONLINE" && (
              <>
                <TableCell align="center">
                  {t("meetingCreated.roles.speaker")}
                </TableCell>
                <TableCell align="center">{t("timeToAgenda")}</TableCell>
              </>
            )}
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agendaData &&
            agendaData?.map((agenda, index) => (
              <AgendaListItem
                agenda={agenda}
                index={index}
                speakers={speakers}
                getAgendaRefetch={getAgendaRefetch}
                agendaDeleteMutate={agendaDeleteMutate}
              />
            ))}
        </TableBody>
      </AgendaTable>
    );
  }
);

export default AgendaList;
