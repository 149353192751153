import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const MeetingParticipants = ({ meetingMember }) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  const userTypeEnum = {
    SPEAKER: t("meetingCreated.roles.speaker"),
    WATCHER: t("meetingCreated.roles.watcher"),
    SECRETARY: t("meetingCreated.roles.secretary"),
    CHAIRMAN: t("meetingCreated.roles.chairman"),
    SIMPLE: t("memberMeeting"),
  };

  return (
    <MeetingParticipantsBox>
      <h3 className="section-title">{t("meetingParticipants")}</h3>
      <ul className="section-list">
        {meetingMember?.map((el, idx) => (
          <Box className="box-list" component={"ul"} key={idx}>
            <Box className="box-list-item" component="li">
              {idx + 1}
            </Box>
            <Box className="box-list-item" component="li">
              {el.user[
                `fullName${lng.charAt(0).toUpperCase() + lng.slice(1)}`
              ] || el.user.fullName}
            </Box>
            <Box className="box-list-item" component="li">
              {userTypeEnum[el.memberTypeEnum]}
            </Box>
          </Box>
        ))}
      </ul>
    </MeetingParticipantsBox>
  );
};

export default MeetingParticipants;

const MeetingParticipantsBox = styled.div`
  padding: 20px 0 20px 0 !important;
  border-bottom: 2px solid #ddf8f0;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .section-title {
    margin-bottom: 20px !important;
    font-size: 22px;
  }

  .section-list {
    min-width: 50%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .box-list {
    margin-bottom: 5px;
    border-radius: 8px;
    border: 1px solid #ddf8f0;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    display: grid;
    grid-template-columns: 8% 55% 30%;
    align-items: center;
    gap: 20px;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .box-list-item {
    padding: 10px;
    text-align: center;
  }
`;
