import React, { useMemo, useState } from "react";
import { DeleteIcon, EditIcon } from "../../../../../../../../assets/icons";
import { Button } from "@mui/base";
import styled from "styled-components";
import { TableCell, TableRow } from "@mui/material";
import { AgendaEditModal } from "../";
import { ButtonSpinner } from "../../../../../../../../components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AgendaListItem = ({
  agenda,
  index,
  speakers,
  getAgendaRefetch,
  agendaDeleteMutate,
  agendaDeleteLoading,
}) => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  const meetingType = JSON.parse(
    localStorage.getItem("newMeetnigId")
  ).meetingType;

  const time = {
    TWOMIN: t("pages.agenda.minute2"),
    FIVEMIN: t("pages.agenda.minute5"),
    TENMIN: t("pages.agenda.minute10"),
    FIFTEENMIN: t("pages.agenda.minute15"),
    TWENTYMIN: t("pages.agenda.minute20"),
  };

  const { id } = agenda;
  const [open, setOpen] = useState(false);
  const agendaId = useMemo(() => {
    return id;
  }, [id]);

  const deleteAgenda = (id) => {
    agendaDeleteMutate(id);
  };

  return (
    <TableRow
      key={index}
      className="list-item"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell sx={{ marginLeft: "15px" }} component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell
        style={{
          textAlign: "left",
        }}
        align="left"
      >
        {agenda[`subject_${lng}`]}
      </TableCell>
      {meetingType !== "ONLINE" && (
        <>
          <TableCell align="right">{agenda[`userName_${lng}`]}</TableCell>
          {/* <TableCell align="right">{time[agenda.debateEnum]}</TableCell> */}
          <TableCell align="right">
            {agenda.speakTimeEnum + " daqiqa"}
          </TableCell>
        </>
      )}

      <TableCell align="right">
        <MyButton onClick={() => setOpen(true)}>
          <img src={EditIcon} alt="" />
        </MyButton>
        {open && (
          <AgendaEditModal
            speakers={speakers}
            id={agendaId}
            open={open}
            setOpen={setOpen}
            getAgendaRefetch={getAgendaRefetch}
          />
        )}
      </TableCell>

      <TableCell align="right">
        {agendaDeleteLoading ? (
          <ButtonSpinner />
        ) : (
          <img src={DeleteIcon} alt="" onClick={() => deleteAgenda(agendaId)} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default AgendaListItem;

const MyButton = styled(Button)`
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
