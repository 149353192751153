import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";

const PrivateRoute = ({ children, path }) => {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const protectRole = () => {
    if (isLogged && user_role === "ROLE_ADMIN") {
      if (
        path.includes("/admin") ||
        path.includes("/profile") ||
        path.includes("/settings")
      ) {
        return children;
      } else {
        return <Navigate to={routeNames.LOGIN} replace />;
      }
    }
    if (isLogged && user_role === "ROLE_USER") {
      if (
        path.includes("/user") ||
        path.includes("/profile") ||
        path.includes("/settings")
      ) {
        return children;
      } else {
        return <Navigate to={routeNames.LOGIN} replace />;
      }
    }
  };

  if (!isLogged) {
    return <Navigate to={routeNames.LOGIN} replace />;
  }

  return protectRole();
};

export default PrivateRoute;
