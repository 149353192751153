import React, { forwardRef, useEffect } from "react";
import JoditEditor from "jodit-react";

const JoditEditorComponent = forwardRef(
  ({ value, handleChange, name, id }, ref) => {
    const config = {
      readonly: false,
      placeholder: "Start typing...",
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
    };

    return (
      <div>
        <JoditEditor
          ref={ref}
          value={value}
          config={config}
          tabIndex={1}
          id={id}
          onBlur={(newContent) => {
            handleChange({
              key: name,
              value: newContent, // Save Jodit editor content to state
            });
          }}
        />
      </div>
    );
  }
);

export default JoditEditorComponent;
