const routeNames = {
  LOGIN: "/",
  ADMIN: "/admin",
  ADMIN_COMPANY: "/admin/company",
  ADMIN_MEETINGS: "/admin/meetings",
  ADMIN_MEETINGS_EDIT: "/admin/meetings/edit/:meetingId",
  ADMIN_USER: "/admin/user",
  ADMIN_USER_CREATE: "/admin/user/create",
  ADMIN_USER_EDIT: "/admin/user/edit",
  ADMIN_MEETING_CREATE: "/admin/meetings/create",
  ADMIN_MEETING_CREATED_MEETING: "/admin/meetings/create/:meetingId",
  ADMIN_MEETING_EDIT: "/admin/meetings/edit",
  ADMIN_COMPANY_EDIT: "/admin/company/edit",
  ADMIN_COMPANY_CREATE: "/admin/company/create",
  USER_COMPANIES: "/user/companies",
  USER_MEETINGS: "/admin/comp/meetings",
  USER_MEETINGS_ONE: "/user/comp/meetings",
  USER_MEETING_DETAIL: "/user/meetings/:id/:type/:memberId",
  ADMIN_MEETING_DETAIL: "/admin/meetings/:id/:type/:memberId",
  USER_VOITS: "/user/:voit/voits/:agenda",
  PROFILE: "/profile",
  ADMIN_SECRETARY: "/admin/secretary",
  EDIT_PROFILE: "/profile/edit",
  MEETING_DETAILS: "/admin/meeting-details/:meetingId",
  MEETING_DETAILS_USER: "/user/meeting-details/:meetingId",
  ARCHIVE_MEETING: "/archive-meeting/meeting-details/:meetingId",
  MEETING_PROTOCOL_QR_CODE_PAGE: "/meeting-protocol/detail/:meetingId",
  SETTINGS: "/settings",
};

export default routeNames;
