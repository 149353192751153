import { Box, CircularProgress } from "@mui/material";
import { UserTable, Wrapper } from "./style";
import { Link } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import routeNames from "../../../constants/routeNames";
import { API } from "../../../services/api";
import { useMutation, useQuery } from "react-query";
import Pagination from "../../../components/Pagination";
import { useEffect, useState } from "react";
import { PAGE_SIZE } from "../../../constants";
import { toast } from "react-toastify";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MyButton } from "../../../components";
import { green } from "@mui/material/colors";
import SearchUser from "./components/SearchUser/SearchUser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function UserData() {
  const [menuToggle, setMenuToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [memberPage, setMemberPage] = useState(1);
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const [orderType, setOrderType] = useState("asc");

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(`all-user-pagination${memberPage}`, async () => {
    const response = await API.getPaginationModerUserData({
      page: memberPage - 1,
      size: 10,
      orderType: orderType,
    });
    return response;
  });

  const changeOrderType = () => {
    orderType === "asc" ? setOrderType("desc") : setOrderType("asc");
  };

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  const handleToggleMenu = (evt, index) => {
    if (!evt.target.matches(".delete-icon")) {
      setMenuToggle(!menuToggle);
      setActiveIndex(index);
    }
  };

  const { mutate, isLoading: deleteUserLoading } = useMutation(
    async (userId) => {
      return await API.adminUsersDelete(userId)
        .then((res) => {
          toast.success(t("toast.userToast.userDeleteSuccessful"));
          refetch();
        })
        .catch((err) => {
          toast.error(t("toast.userToast.userDeleteError"));
          console.log("Xatolik yuzberdi delete qilishda!", err);
        })
        .finally(() => {
          setMenuToggle(!menuToggle);
          setActiveIndex(activeIndex);
        });
    }
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  const deleteUser = (id) => {
    mutate(id);
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="user-top">
          <Link className="user-link" to="/admin">
            {t("home")}
          </Link>
          <h5>{t("userManagement")}</h5>
        </Box>
        <Box className="user-list">
          <SearchUser
            mutate={mutate}
            deleteUser={deleteUser}
            deleteUserLoading={deleteUserLoading}
          />
          <Link className="user-create" to={routeNames.ADMIN_USER_CREATE}>
            {t("UsersHeading.save")}
          </Link>
        </Box>
        <UserTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>T/R</TableCell>
              <TableCell align="center">
                <div className="fullName_card" onClick={changeOrderType}>
                  {/* <div className="fullName_card_icons">
                    <img
                      src={UpIcon}
                      alt="up-iocn"
                      onClick={() => setSorting("z-a")}
                      style={sorting == "z-a" ? { display: "none" } : {}}
                    />
                    <img
                      src={DownIcon}
                      alt="down-icon"
                      onClick={() => setSorting("a-z")}
                      style={sorting == "a-z" ? { display: "none" } : {}}
                    />
                  </div> */}
                  {t("AdminUser.fullName")}
                </div>
              </TableCell>
              <TableCell align="center">{t("AdminUser.userName")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.data &&
              users?.data?.map((user, index) => (
                <TableRow
                  key={index}
                  onClick={(evt) => handleToggleMenu(evt, index)}
                  className="list-item"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">{user[`fullName_${lng}`]}</TableCell>
                  <TableCell align="right">{user.login}</TableCell>
                  {menuToggle && index === activeIndex && (
                    <TableCell>
                      <Box position="absolute" className="menu-box">
                        {deleteUserLoading ? (
                          <MyButton>
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                              }}
                            />
                          </MyButton>
                        ) : (
                          <button
                            className="user-delete"
                            onClick={() => deleteUser(user?.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="DeleteIcon"
                              className="delete-icon"
                            />
                          </button>
                        )}
                        <Link
                          state={{ useresId: user?.id }}
                          to={routeNames.ADMIN_USER_EDIT}
                        >
                          <img src={EditIcon} alt="EditIcon" />
                        </Link>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </UserTable>
        <Pagination
          className="pagination-bar"
          currentPage={memberPage}
          totalCount={!!users.data && users.headers["x-total-count"]}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setMemberPage(page)}
        />
      </div>
    </Wrapper>
  );
}
export default UserData;
