import styled from "styled-components";

export const GeneralStyle = styled.div`
  .create-select {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 350px;
    height: 48px;
    margin-top: 10px;
  }

  label {
    width: 350px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    color: var(--text-drak, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .general-item {
    display: flex;
    gap: 25px;
  }

  input {
    border-radius: 6px;
    border: 1.5px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 325px;
    height: 48px;
    padding: 0 12px;
    margin-top: 10px;
    outline: none;
  }

  input::placeholder {
    color: #9d9d9d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  textarea {
    border-radius: 6px;
    border: 1.5px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 325px;
    height: 125px;
    padding: 8px 12px;
    margin-top: 10px;
    outline: none;
    resize: none;
  }

  .general-submit {
    border-radius: 8px;
    background: #208667;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    width: 150px;
    height: 48px;
    cursor: pointer;
  }

  .lang_cards {
    width: 338px;
    height: 38px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border: 2px solid #898686;
    padding: 5px;
    border-radius: 8px;
    margin-top: 35px;

    .lang_label {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      justify-content: center;
      gap: 5px;
      color: #208667;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;

      span {
        padding-top: 6.5px;
      }

      input {
        cursor: pointer;
        width: 18px;
        padding: 0;
      }
    }
  }
`;
