import { Box } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import DeleteButton from "../DeleteButton/DeleteButton";
import {
  SPEAKER,
  WATCHER,
  SECRETARY,
  CHAIRMAN,
  SIMPLE,
} from "../../../../../../../../constants";
import { useMutation } from "react-query";
import { deleteMemberFunc } from "../../extra";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function ParticipantItem({ user, id, role, memberTypeEnum, idx, refetch }) {
  const [deleteToggle, setDeleteToggle] = useState({ isShow: false, id: null });
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);

  const changeDeleteItem = (e) => {
    if (
      !e.target.matches(".delete-button") &&
      !e.target.matches(".delete-icon")
    ) {
      setDeleteToggle((prev) => ({ ...prev, isShow: !prev.isShow, id: id }));
    }
  };

  const userRole =
    memberTypeEnum === SIMPLE
      ? t("memberMeeting")
      : memberTypeEnum === SPEAKER
      ? t("meetingCreated.roles.speaker")
      : memberTypeEnum === WATCHER
      ? t("meetingCreated.roles.watcher")
      : memberTypeEnum === SECRETARY
      ? t("meetingCreated.roles.secretary")
      : memberTypeEnum === CHAIRMAN
      ? t("meetingCreated.roles.chairman")
      : "";

  const { mutate, isLoading: deleteMemberLoading } = useMutation(
    deleteMemberFunc,
    {
      onSuccess: (data) => {
        refetch();
      },
    }
  );

  const deleteMember = (id) => {
    mutate(id);
  };
  return (
    <ParticipantItemBox onClick={changeDeleteItem}>
      <Box className="box-list" component="ul">
        <Box className="box-list-item" component="li">
          {idx + 1}
        </Box>

        <Box
          className="box-list-item"
          component="li"
          style={{
            textAlign: "left",
          }}
        >
          {lng === "uz"
            ? user?.fullName
            : lng === "ru"
            ? user?.fullNameRu
            : user?.fullNameEn}
        </Box>

        <Box className="box-list-item" component="li">
          {userRole}
        </Box>
      </Box>

      {deleteToggle.isShow && deleteToggle.id === id && (
        <DeleteButton
          onClick={() => deleteMember(id)}
          loading={deleteMemberLoading}
        />
      )}
    </ParticipantItemBox>
  );
}
export default ParticipantItem;

const ParticipantItemBox = styled.li`
  position: relative;
  z-index: 100;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid #ddf8f0;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  width: 100%;

  .box-list {
    display: grid;
    grid-template-columns: 7% 60% 33%;
    padding: 0px 5px;
    align-items: center;
    /* padding-left: 0; */
  }

  .box-list-item {
    padding: 10px;
    text-align: center;
    /* border: 1px solid gray; */
  }
`;
