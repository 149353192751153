import React, { useEffect, useRef, useState } from "react";
import {
  InputFile,
  MyInput,
  MySelect,
  MyTextarea,
} from "../../../../../../../../components";
import { AgendaFormBox } from "./AgendaFormStyle";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../../../../utils";
import { Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { API } from "../../../../../../../../services/api";
import { DeleteIcon } from "../../../../../../../../assets/icons";
import JoditEditorComponent from "./JoditEditorComponent";

const AgendaForm = ({
  data,
  setData,
  speakers,
  setIssueFile,
  issueFile,
  loading,
  isSuccess,
  answers,
  editIssueFile,
  deleteAgendaFile,
}) => {
  const { t } = useTranslation();
  const inputFileRef = useRef(null);

  const meetingType = JSON.parse(
    localStorage.getItem("newMeetnigId")
  ).meetingType;

  const editorUz = useRef(null); // Editor uchun ref
  const editorRu = useRef(null);
  const editorEn = useRef(null);

  const handleChange = ({ key, value }) => {
    const newData = {
      ...data,
      [key]: value,
    };
    setData(newData);
  };

  const handleChangeFile = (files) => {
    const newFiles = Array.from(files);
    setIssueFile((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (id) => {
    setIssueFile((prevFiles) => prevFiles.filter((_, index) => index !== id));
  };

  const meetingLangs = JSON.parse(localStorage.getItem("newMeetnigLngs"));

  useEffect(() => {
    if (isSuccess) {
      setData({
        subject_uz: "",
        subject_ru: "",
        subject_en: "",
        speakerId: "",
        speakTimeEnum: "",
        issueInfo_uz: "",
        issueInfo_ru: "",
        issueInfo_en: "",
        editor_uz: "",
        editor_ru: "",
        editor_en: "",
      });
      changeValue();
      inputFileRef.current.value = null;
    }
  }, [isSuccess]);

  function changeValue() {
    {
      meetingLangs.includes("uz") &&
        (document.getElementById("issue_uz").value = "");
    }
    {
      meetingLangs.includes("ru") &&
        (document.getElementById("issue_ru").value = "");
    }
    {
      meetingLangs.includes("en") &&
        (document.getElementById("issue_en").value = "");
    }
    // document.getElementById("JEditor_uz").value = "";
    // document.getElementById("JEditor_ru").value = "";
    // document.getElementById("JEditor_en").value = "";
  }

  return (
    <AgendaFormBox>
      <form id="agendaForm">
        {meetingLangs.includes("uz") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("meetingCreated.filePage.agenda")}_uz</span>
            </label>
            <MyInput
              placeholder={t("companyForm.enter")}
              value={data.subject_uz}
              name="subject_uz"
              required={true}
              onChange={(e) =>
                handleChange({
                  key: "subject_uz",
                  value: editInputText(e.target.value),
                })
              }
            />
          </div>
        )}

        {meetingLangs.includes("ru") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("meetingCreated.filePage.agenda")}_ru</span>
            </label>
            <MyInput
              placeholder={t("companyForm.enter")}
              value={data.subject_ru}
              name="subject_ru"
              required={true}
              onChange={(e) =>
                handleChange({
                  key: "subject_ru",
                  value: editInputText(e.target.value),
                })
              }
            />
          </div>
        )}

        {meetingLangs.includes("en") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("meetingCreated.filePage.agenda")}_en</span>
            </label>
            <MyInput
              placeholder={t("companyForm.enter")}
              value={data.subject_en}
              name="subject_en"
              required={true}
              onChange={(e) =>
                handleChange({
                  key: "subject_en",
                  value: editInputText(e.target.value),
                })
              }
            />
          </div>
        )}

        {meetingType !== "ONLINE" ? (
          <div className="form-grid">
            <div className="form-col">
              <label htmlFor="" className="form-label">
                <span>{t("meetingCreated.roles.speaker")}</span>
              </label>
              <MySelect
                placeholder={t("chooseSpeaker")}
                value={
                  !!data.speakerId
                    ? speakers.find((el) => el.id === data.speakerId)?.label
                    : ""
                }
                data={speakers}
                onChange={(value, option) => {
                  handleChange({ key: "speakerId", value: option?.id });
                }}
                loading={loading}
                maxwidth="319px"
                required={meetingType == "ONLINE" ? false : true}
              />
            </div>

            <div className="form-col">
              <label htmlFor="" className="form-label">
                <span>{t("regulationTime")}</span>
              </label>
              <div className="minute_input">
                <MyInput
                  type="number"
                  max={999}
                  value={data.speakTimeEnum || ""}
                  onChange={(e) =>
                    handleChange({
                      key: "speakTimeEnum",
                      value: e.target.value,
                    })
                  }
                  maxwidth="319"
                  required={true}
                  className="input-with-span"
                />
              </div>
            </div>
          </div>
        ) : null}

        {meetingLangs.includes("uz") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("aboutIssue")}_uz</span>
            </label>
            <MyTextarea
              placeholder={t("companyForm.enter")}
              value={data.issueInfo_uz}
              id="issue_uz"
              name="issueInfo_uz"
              onChange={(e) =>
                handleChange({
                  key: "issueInfo_uz",
                  value: editInputText(e.target.value),
                })
              }
              rows="4"
              required={true}
            />
          </div>
        )}

        {meetingLangs.includes("ru") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("aboutIssue")}_ru</span>
            </label>
            <MyTextarea
              placeholder={t("companyForm.enter")}
              value={data.issueInfo_ru}
              id="issue_ru"
              name="issueInfo_ru"
              onChange={(e) =>
                handleChange({
                  key: "issueInfo_ru",
                  value: editInputText(e.target.value),
                })
              }
              rows="4"
              required={true}
            />
          </div>
        )}

        {meetingLangs.includes("en") && (
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("aboutIssue")}_en</span>
            </label>
            <MyTextarea
              placeholder={t("companyForm.enter")}
              value={data.issueInfo_en}
              id="issue_en"
              name="issueInfo_en"
              onChange={(e) =>
                handleChange({
                  key: "issueInfo_en",
                  value: editInputText(e.target.value),
                })
              }
              rows="4"
              required={true}
            />
          </div>
        )}
        {meetingLangs.includes("uz") && (
          <div style={{ marginBottom: "15px" }}>
            <Typography>{t("userPage.protocol.title") + " uz"}</Typography>
            {/* <CKEditor
            data={answers?.answer_uz || data.editor_uz}
            // data={data.editor_uz}
            name="editor_uz"
            editor={ClassicEditor}
            config={{
              removePlugins: ["Autoformat", "PasteFromWord"],
              allowedContent: false,
              disallowedContent: "*[*]",
            }}
            onReady={(editor) => {
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const editorData = editor.getData(); // Get data from CKEditor
              handleChange({
                key: "editor_uz",
                value: editorData, // Use CKEditor's data as the value
              });
            }}
          /> */}
            <JoditEditorComponent
              value={answers?.answer_uz || data.editor_uz}
              handleChange={handleChange}
              name="editor_uz"
              ref={editorUz}
              id="JEditor_uz"
            />
          </div>
        )}

        {meetingLangs.includes("ru") && (
          <div style={{ marginBottom: "15px" }}>
            <Typography>{t("userPage.protocol.title") + " ru"}</Typography>
            {/* <CKEditor
            data={answers?.answer_ru || data.editor_ru}
            // data={data.editor_ru}
            name="editor_ru"
            editor={ClassicEditor}
            config={{
              removePlugins: ["Autoformat", "PasteFromWord"],
              allowedContent: false,
              disallowedContent: "*[*]",
            }}
            onReady={(editor) => {
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const editorData = editor.getData(); // Get data from CKEditor
              handleChange({
                key: "editor_ru",
                value: editorData, // Use CKEditor's data as the value
              });
            }}
          /> */}
            <JoditEditorComponent
              value={answers?.answer_ru || data.editor_ru}
              handleChange={handleChange}
              name="editor_ru"
              ref={editorRu}
              id="JEditor_ru"
            />
          </div>
        )}

        {meetingLangs.includes("en") && (
          <div style={{ marginBottom: "15px" }}>
            <Typography>{t("userPage.protocol.title") + " en"}</Typography>
            {/* <CKEditor
            data={answers?.answer_en || data.editor_en}
            // data={data.editor_en}
            name="editor_en"
            editor={ClassicEditor}
            config={{
              removePlugins: ["Autoformat", "PasteFromWord"],
              allowedContent: false,
              disallowedContent: "*[*]",
            }}
            onReady={(editor) => {
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const editorData = editor.getData(); // Get data from CKEditor
              handleChange({
                key: "editor_en",
                value: editorData, // Use CKEditor's data as the value
              });
            }}
          /> */}

            <JoditEditorComponent
              value={answers?.answer_en || data.editor_en}
              handleChange={handleChange}
              name="editor_en"
              ref={editorEn}
              id="JEditor_en"
            />
          </div>
        )}

        <div className="form-col">
          <div>
            <label htmlFor="" className="form-label">
              <span>{t("agendaFile")}</span>
            </label>
            <InputFile
              value={""}
              name="issueFile"
              changeValue={(val) => handleChangeFile(val.files)}
              ref={inputFileRef}
              multiple
            />
            {editIssueFile?.length > 0 && (
              <div>
                {editIssueFile.map((el, idx) => (
                  <div className="img_cards" key={idx}>
                    <button
                      type="button"
                      onClick={() => deleteAgendaFile(el.id)}
                    >
                      <img src={DeleteIcon} alt="delete icon" />
                    </button>
                    <span>{" " + el.name}</span>
                  </div>
                ))}
              </div>
            )}
            {!!issueFile.length && (
              <div>
                {issueFile.map((el, idx) => (
                  <div className="img_cards">
                    <button type="button" onClick={() => handleFileDelete(idx)}>
                      <img src={DeleteIcon} alt="delete icon" />
                    </button>
                    <span>{" " + el.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </form>
    </AgendaFormBox>
  );
};

export default AgendaForm;
