import React from "react";
import ParticipantItem from "../ParticipantItem";
import styled from "styled-components";

function ParticipantList({ data, refetch }) {
  return (
    <ParticipantListBox>
      {data?.map((el, idx) => (
        <ParticipantItem {...el} key={el.id} idx={idx} refetch={refetch} />
      ))}
    </ParticipantListBox>
  );
}

export default ParticipantList;

const ParticipantListBox = styled.ul`
  position: relative;
  z-index: 1;
  padding-left: 0;
  height: 436px;
  /* overflow-y: auto; */

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ddf8f0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #208667;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #208667;
    border-radius: 10px;
  }
`;
