import styled from "styled-components";
import bgImg from "../../../../assets/images/kotib/2.png";

export const ProtocolDiv = styled.div`
  width: 100%;
  /* max-width: 682.5px; */
  height: 330px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 30px;
  padding-left: 0;
  font-family: "Inter";
  border-radius: 12px;
  background-image: url(${bgImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;
`;

export const ImageDiv = styled.div`
  width: 60%;
`;

export const ProtocolRight = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProtocolTop = styled.div`
  text-align: center;

  .user-protocol-signin {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  h2 {
    color: #208667;
  }
  .user-protocol-signin--text_box {
    display: flex;
    align-items: center;
  }
  .user-protocol-signin--text {
    display: inline-block;
    font-size: 14px;
    max-width: 140px;
  }

  .quorum_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 8px;
    background: #208667;
    outline: none;
    cursor: pointer;
  }
`;

export const ProtocolBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 8px;
    background: #208667;
    outline: none;
    cursor: pointer;
  }

  .editing-btns {
    display: flex;
    align-items: center;

    .reset-btn {
      background: transparent;
      border: none;
    }
  }

  .lang_cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border: 2px solid #898686;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 20px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #208667;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }

  .btns_card {
    display: flex;
    gap: 15px;
    justify-content: space-between;

    button:active {
      transform: scale(0.95);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const Wrapper = styled.div`
  div {
    width: 350px;
  }
  h1 {
    font-size: 22px;
    line-height: 30px;
  }

  img {
    width: 30px;
    height: 45px;
    margin-left: 15px;
    object-fit: contain;
  }

  label {
    display: flex;
  }
  select {
    appearance: none;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
  }

  button {
    width: 120px;
    height: 40px;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
  }

  .wrapper-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
`;
